<template>
  <v-container fluid>
    <v-card class="mx-auto">
      <v-card-text>
        <div v-for="goal in goals" v-bind:key="goal.id" class="mb-4">

          <h2>{{ goal.goal.displayName}} <span :class="color(goal)">{{ goal.publications.length }}/{{ goal.value }}</span></h2>

              <v-simple-table>
                <thead>
                <tr>
                  <th>Type</th>
                  <th>Name</th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="item in goal.publications" v-bind:key="item.id">
                  <td>{{ item.type.displayName }}</td>
                  <td><router-link :to="generateLink(item)">{{ item.title }}</router-link></td>
                </tr>
                </tbody>
              </v-simple-table>

        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "PublicationGoals",
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters('publicationUnitGoal', ['goals'])
  },
  methods: {
    generateLink(publication) {
      return {name: 'se2a-publication-detail', params: {publicationId: publication.id}}
    },
    color(goal) {
      if(goal.publications.length === goal.value) {
        return 'green--text';
      } else if (goal.publications.length === 0) {
        return 'red--text';
      } else {
        return 'yellow--text';
      }
    }
  }
}
</script>

<style scoped>

</style>
